<template>
  <div style="padding:1px">
    <div class="container">
      <!-- 头部导航  -->
      <downPageTopmb />
    </div>
    <div class="content-box">
      <el-image :src="contentList" width="100%" style="width: 100%;" @load="imgOnload" lazy>
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="button_Enbox" style="right: 8%;">
        <div class="" style="display: flex;justify-content: center;margin-bottom: 10px;">
          <el-image v-if="showBtn" class="more" @click="openUrl('https://www.youtube.com/@travelcontents')" :src="moreDownlaodBtn" width="100%" lazy>
            <div slot="placeholder" class="image-slot">
              {{$t('加载中')}}<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="" style="display:flex;justify-content: center;">
          <el-image v-if="showBtn" class="app" @click="openUrl('https://apps.apple.com/jp/app/travel-contents/id1597706553')" :src="appleDownlaodBtn" width="100%" lazy>
            <div slot="placeholder" class="image-slot">
              {{$t('加载中')}}<span class="dot">...</span>
            </div>
          </el-image>
          <el-image v-if="showBtn" class="google" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="googleDownlaodBtn" width="100%" lazy>
            <div slot="placeholder" class="image-slot">
              {{$t('加载中')}}<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
    </div>
    <!-- <div v-show="language==='ja'">
      ダウンロードは保留中
    </div> -->

  </div>
</template>
<script>
import downPageTopmb from "@/components/downPage-top-mb";
import { downloadPageB_list_MB } from "@/utils/public_data";
export default {
  data() {
    return {
      contentList: require("@/assets/images/downloadPage/B/KR_B.jpg"),
      showBtn: false,
      appleDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_apple.png"),
      googleDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_google.png"),
      androidDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_android.png"),
      moreDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_more.png"),
    };
  },
  mounted() {
    // 进入页面回到顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    imgOnload() {
      this.showBtn = true;
      this.$forceUpdate();
    },
  },
  components: {
    downPageTopmb,
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  // padding: 0 16px;
  // box-sizing: border-box;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.button-box {
  position: absolute;
  bottom: 2%;
  left: 8%;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .android,
  .app {
    display: block;
    width: 42%;
    margin: 0 5px;
  }
  .el-image{
    width: 50%;
  }
}
.button_Enbox {
  position: absolute;
  bottom: 4%;
  left: 8%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  .google,
  .app,
  .more {
    display: block;
    width: 42%;
    margin: 0 5px;
  }
}
</style>