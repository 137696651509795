import { render, staticRenderFns } from "./downPage-top-mb.vue?vue&type=template&id=add9f644&scoped=true&"
import script from "./downPage-top-mb.vue?vue&type=script&lang=js&"
export * from "./downPage-top-mb.vue?vue&type=script&lang=js&"
import style0 from "./downPage-top-mb.vue?vue&type=style&index=0&id=add9f644&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add9f644",
  null
  
)

export default component.exports