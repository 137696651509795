<template>
  <div class="nav-box">
    <div class="nav-left">
      <van-image width="30" :src="logo" @click="pageHandle('/home')" />
    </div>
    <div class="nav-right">
      <van-popover v-model="showPopover" trigger="click" placement="bottom-end" :actions="actions" @select="onSelect">
        <template #reference>
          <span type="primary" style='color: #FF7B00;font-size: 17px;font-family: "GenSenRoundedTW-M";'>
            Language ({{ lan }})
            <el-image :src="lanLogo" style="width: 20px;line-height: 0px;padding-top:4px;" lazy />
          </span>
        </template>
      </van-popover>
    </div>
  </div>
</template>
<script>
import { reloadMessage } from "@/lang";
import { homeLanList } from "@/utils/public_data";
export default {
  data() {
    return {
      logo: require("@/assets/images/customer/MB-home.png"),
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      lanList: homeLanList(),
      showPopover: false,
      screenWidth: document.body.clientWidth,
      actions: [
        { text: "简体中文", lan: "zh" },
        { text: "繁體中文", lan: "zh_tw" },
        { text: "English", lan: "en" },
        { text: "한국어", lan: "ko" },
      ],
    };
  },
  mounted() {
    // document.querySelector('.nav-right').style.marginLeft = `${this.screenWidth - this.screenWidth * 0.69}px`
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    onSelect(action) {
      console.log(action);
      this.$i18n.locale = action.lan;
      localStorage.setItem("locale", action.lan);
      reloadMessage();
      action.lan==='zh'?this.$router.push('/tcapp-download-b/sc'):''
      action.lan==='zh_tw'?this.$router.push('/tcapp-download-b/tc'):''
      action.lan==='en'?this.$router.push('/tcapp-download-b/en'):''
      action.lan==='ko'?this.$router.push('/tcapp-download-b/kr'):''
      // this.$router.go(0);
    },
  },
};
</script>
  <style lang="scss" scoped>
.nav-box {
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  .nav-left {
    height: 44px;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  .nav-right {
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
}
</style>